import { useEffect, useState } from 'react';

/**
 * Date is the local timezone, boolean is whether the Date has been fully hydrated on the client
 */
export type useLocalTimestampResult = [Date, boolean];

/**
 * This hook will provide the Date as UTC on the server and once the client is hydrated will then provide
 * it in the browsers local timezone.
 * @param timestamp The timestamp you want the local Date representation of
 */
export const useLocalTimestamp = (timestampInSeconds: number): useLocalTimestampResult => {
    const [date, setDate] = useState<Date>(
        new Date(
            timestampInSeconds * 1000 +
                new Date(timestampInSeconds * 1000).getTimezoneOffset() * 60000
        )
    );
    const [isHydrated, setIsHydrated] = useState<boolean>(false);

    useEffect(() => {
        setIsHydrated(true);
    }, []);

    useEffect(() => {
        if (isHydrated === false) {
            return;
        }
        setDate(new Date(timestampInSeconds * 1000));
    }, [isHydrated, timestampInSeconds]);

    return [date, isHydrated];
};
