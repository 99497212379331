import { LegacySearchIndexCourse } from '@shared/types';
import { useLocalTimestamp } from '../../hooks/useTimestamp';

const ON_REQUEST_START_DATE = 9999999999;

const CourseDateText = ({ course }: { course: LegacySearchIndexCourse }) => {
    const [startDate] = useLocalTimestamp(course.start_datetime || 0);
    if (course.delivery.length === 1 && course.delivery[0] === 'on-demand')
        return <span>Pre-recorded Online course</span>;

    if (!course.start_datetime) return null;

    if (course.start_datetime === ON_REQUEST_START_DATE) {
        if (course.enquiry_types.length > 0) return <>Flexible Dates</>;

        return null;
    }

    const dateFormatter = new Intl.DateTimeFormat('en-GB', {
        month: 'short',
        weekday: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    const { month, weekday, day, hour, minute } = dateFormatter.formatToParts(startDate).reduce(
        (acc, part) => {
            acc[part.type] = part.value;
            return acc;
        },
        {} as Record<string, string>
    );

    const numberOfOtherTimes = course.start_datetimes.length - 1;
    return (
        <>
            {weekday}, {month} {day} • {hour}:{minute} {numberOfOtherTimes > 0 ? <>+ more</> : null}
        </>
    );
};

export default CourseDateText;
